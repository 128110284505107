<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="true"
                title="Warehouse List"
                @onClickAddNewButton="onClickAddNewButton"
            />
            <div class="row match-height mt-1">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
                    <DateQuerySetter
                        @onClickGo="getJournals"
                    />
                </div>
            </div>
        </div>

        <ListTable />
        <div class="mb-2"></div>
        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>        
    </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/inventory/warehouse/ListingTable'
import Pagination from '@/components/atom/Pagination'

const router = useRouter()
const route = useRoute()

function onClickAddNewButton() {
  router.push({name: 'warehouse-add', params: route.params, query: route.query})
}

function getJournals() {
    //todo
}
function onPageChange() {
    //todo
}

</script>
