<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Voucher No</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>12/3/22</td>
        <td>#f4545</td>
        <td>Description</td>
        <td>Amount</td>
        <td>View</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
